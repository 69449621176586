<template>
	<div>
		<div class="header"><appHeader></appHeader></div>
		<div style="height: 84px;"></div>
		<div class="row cc main-container">
			<div class="row page-main">
				<div style="width: 139px;margin-right: 14px;">
					<div class="sidebar-item"><appSidebar></appSidebar></div>
				</div>
				<div style="min-width: 732px;height: 100%;position: sticky;top: 84px;">
					<keep-alive><slot /></keep-alive>
				</div>
				<div style="width: 308px;margin-left: 14px;"><appSidebarRight></appSidebarRight></div>
			</div>
		</div>
		<appSideToolbar></appSideToolbar>
	</div>
</template>

<script>
import appHeader from './components/header.vue';
import appSidebar from './components/sidebar.vue';
import appSidebarRight from './components/sidebarRight.vue';
import appSideToolbar from './components/sideToolbar.vue';
export default {
	components: { appHeader, appSidebar, appSidebarRight, appSideToolbar },
	data() {
		return {};
	}
};
</script>
<style type="text/css">
body {
	background-color: #f6f6f6;
}
</style>
<style scoped lang="less">
.header {
	position: fixed;
	top: 0;
	z-index: 101;
	width: 100%;
}
.main-container {
	.page-main {
		width: 1207px;
		.sidebar-item {
			width: 139px;
			position: fixed;
			top: 84px;
			z-index: 100;
		}
	}
}
</style>
