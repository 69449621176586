<template>
	<div class="part-box" v-if="token">
		<div class="part-title">
			<div class="row title"><span class="row title-text">常用功能</span></div>
		</div>
		<div class="col list" v-loading="loading">
			<div class="row cb item a" @click="navUserHome()">
				<div class="row left">
					<el-image class="img" fit="scale-down" :src="require('@/assets/mine/1.png')"></el-image>
					<div class="name text-ellipsis">我回答的问题</div>
				</div>
				<div class="count" v-if="!loading">{{ userInfo.my_answer_que }}</div>
			</div>
			<div class="row cb item a" @click="navUserHome()">
				<div class="row left">
					<el-image class="img" fit="scale-down" :src="require('@/assets/mine/2.png')"></el-image>
					<div class="name text-ellipsis">我关注的问题</div>
				</div>
				<div class="count" v-if="!loading">{{ userInfo.my_focus_que }}</div>
			</div>
			<div class="row cb item a" @click="navUserHome()">
				<div class="row left">
					<el-image class="img" fit="scale-down" :src="require('@/assets/mine/3.png')"></el-image>
					<div class="name text-ellipsis">积分余额</div>
				</div>
				<div class="count" v-if="!loading">{{ userInfo.point }}</div>
			</div>
			<div class="row cb item a" @click="navUserHome()">
				<div class="row left">
					<el-image class="img" fit="scale-down" :src="require('@/assets/mine/4.png')"></el-image>
					<div class="name text-ellipsis">历史收益</div>
				</div>
				<div class="count" v-if="!loading">{{ userInfo.money }}元</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	inject: ['initSuccess'],
	data() {
		return {
			loading: true,
			userInfo: {}
		};
	},
	computed: { ...mapState(['token']) },
	async created() {
		if (this.token) {
			let { data } = await this.$service.post('user_info/detail');
			this.userInfo = data;
			this.loading = false;
			this.initSuccess();
		} else {
			this.loading = false;
			this.initSuccess();
		}
	},
	methods: {
		navUserHome() {
			this.$router.push({
				path: '/user/home'
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
@import './tool.less';
</style>
