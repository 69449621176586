<template>
	<div class="col side-toolbar" v-if="setting.customer_image">
		<el-popover placement="left" trigger="click">
			<div>
				<div class="col cb popover-main">
					<div class="row cb text" style="margin-top: 0;margin-bottom: 10px;">
						<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat"></el-image>
						<span>微信扫描二维码分享</span>
					</div>
					<el-image fit="contain" :src="pageUrlQrCode" class="qr"></el-image>
					<div class="row cc text copy a" @click="copyLink()">
						<i class="el-icon-copy-document"></i>
						<span>复制链接</span>
					</div>
				</div>
			</div>
			<div slot="reference" class="row cc img-item a"><el-image fit="contain" :src="require('@/pc/assets/side/1.png')" class="img"></el-image></div>
		</el-popover>
		<el-popover placement="left" trigger="click">
			<div>
				<div class="col cb popover-main">
					<el-image fit="contain" :src="$store.getters.oss(setting.customer_image)" class="qr"></el-image>
					<div class="row cb text">
						<el-image fit="contain" :src="require('@/pc/assets/login/wechat.png')" class="wechat"></el-image>
						<span>问题反馈 / 合作咨询</span>
					</div>
				</div>
			</div>
			<div slot="reference" class="row cc img-item a border"><el-image fit="contain" :src="require('@/pc/assets/side/2.png')" class="img"></el-image></div>
		</el-popover>
		<div class="row cc img-item a" @click.stop="reset()"><el-image fit="contain" :src="require('@/pc/assets/side/3.png')" class="img"></el-image></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			setting: {}
		};
	},
	computed: {
		...mapState(['domain']),
		pageUrlQrCode() {
			return this.$store.getters.qrcode(this.$route.fullPath.slice(1));
		},
		link() {
			return this.domain + this.$route.fullPath.slice(1);
		}
	},
	async created() {
		let { data } = await this.$service.post('auth/setShow');
		this.setting = data;
	},
	methods: {
		copyLink() {
			// 创建输入框
			var textarea = document.createElement('textarea');
			document.body.appendChild(textarea);
			// 隐藏此输入框
			textarea.style.position = 'absolute';
			textarea.style.clip = 'rect(0 0 0 0)';
			// 赋值
			textarea.value = this.link;
			// 选中
			textarea.select();
			// 复制
			document.execCommand('copy', true);

			this.$message({
				message: '链接已复制',
				type: 'success'
			});
		},
		reset() {
			document.documentElement.scrollTop = 0;
		}
	}
};
</script>

<style scoped lang="less">
.side-toolbar {
	position: fixed;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 15px;
	width: 40px;
	height: 130px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
	.border {
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
	}
	.img-item {
		padding: 8px 0;
		flex: 1;
		width: 40px;
		box-sizing: border-box;
		&:nth-of-type(1) {
			.img {
				width: 19px;
				height: 25px;
			}
		}
		&:nth-of-type(2) {
			.img {
				width: 22px;
				height: 22px;
			}
		}
		&:nth-of-type(3) {
			.img {
				width: 18px;
				height: 25px;
			}
		}
	}
}
.popover-main {
	background-color: #ffffff;
	padding: 10px 16px !important;
	box-sizing: border-box;
	.qr {
		width: 100px;
		height: 100px;
	}
	.text {
		margin-top: 10px;
		font-size: 14px;
		color: #999999;
		.wechat {
			width: 18px;
			height: 18px;
			margin-right: 4px;
		}
	}
	.copy {
		margin-top: 6px;
		.el-icon-copy-document {
			margin-right: 4px;
		}
		&:active {
			color: @green;
		}
	}
}
</style>
