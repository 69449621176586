<template>
	<div class="part-box">
		<div class="part-title">
			<div class="row title"><span class="row title-text">本周答题榜</span></div>
		</div>
		<div class="col list" v-loading="loading">
			<template v-for="(item, index) in dataList">
				<div class="row cb item a" @click="navUserHome(item)">
					<div class="row left">
						<div class="row cc rank">
							<template v-if="index == 0 || index == 1 || index == 2">
								<el-image :src="require('@/pc/assets/rank/1.png')" class="img" v-if="index == 0"></el-image>
								<el-image :src="require('@/pc/assets/rank/2.png')" class="img" v-else-if="index == 1"></el-image>
								<el-image :src="require('@/pc/assets/rank/3.png')" class="img" v-else></el-image>
							</template>

							<div class="num" v-else>{{ index + 1 }}</div>
						</div>
						<el-image :src="item.avatar" class="avatar"></el-image>
						<div class="name text-ellipsis">{{ item.nickname }}</div>
					</div>
					<div class="count">回答数：{{ item.answer_num }}</div>
				</div>
			</template>
			<el-empty description="暂无数据" v-if="dataList.length == 0 && !loading"></el-empty>
		</div>
	</div>
</template>

<script>
export default {
	inject: ['initSuccess'],
	data() {
		return {
			loading: true,
			dataList: []
		};
	},
	created() {
		this.$service
			.post('home/answerRank', { pageSize: 5 })
			.then(res => {
				this.dataList = res.data;
			})
			.finally(() => {
				this.loading = false;
				this.initSuccess();
			});
	},
	methods: {
		navAskDetail(item) {
			this.$router.push({
				path: '/ask/detail',
				query: {
					id: item.id
				}
			});
		},
		navUserHome(item) {
			this.$router.push({
				path: '/user/home',
				query: {
					user_id: item.user_id
				}
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
@import './rank.less';
</style>
